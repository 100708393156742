import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import {React} from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

);