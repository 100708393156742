import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'
import {SiBlogger} from 'react-icons/si'
const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href='https://www.blogger.com/blog/posts/8158423887960636513?hl=en' target='_blank'><SiBlogger/></a>
        <a href="https://www.linkedin.com/in/yewantraj-karki-36879a17b/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/yewant" target="_blank"><FaGithub/></a>
        <a href="https://dribbble.com" target="_blank"><FiDribbble/></a>
    </div>
  )
}

export default HeaderSocials